import React, { useState, useEffect, useCallback } from 'react'
import ReactSelect, { OnChangeValue, StylesConfig } from 'react-select'

export interface SearchableSelectOption {
  value: string | number
  label: string
  selected?: boolean
}

interface SearchableSelectProps {
  options: SearchableSelectOption[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (o: any) => void
  updateSelectField?: (o: SearchableSelectOption) => void
  value?: SearchableSelectOption
  initialValue?: SearchableSelectOption
  theme?: string
  name?: string
  placeholder?: string
  error?: boolean
  isClearable?: boolean
}

export function SearchableSelect({
  options,
  updateSelectField,
  initialValue,
  theme,
  error,
  ...props
}: SearchableSelectProps) {
  const [selectedOption, setSelectedOption] = useState<SearchableSelectOption | undefined>(
    initialValue
  )

  const clearSelectValues = useCallback(() => {
    if (updateSelectField) {
      updateSelectField(options[0])
      setSelectedOption(options[0])
    }
  }, [options, updateSelectField])

  useEffect(() => {
    document.addEventListener('clearSelectValues', clearSelectValues)

    return () => {
      document.removeEventListener('clearSelectValues', clearSelectValues)
    }
  }, [clearSelectValues])

  const midBlue = '#0067b2'
  const skyBlue = '#00a3e2'
  const pink = '#e61657'
  const grey30 = '#4d4d4d'
  const grey45 = '#757575'
  const grey85 = '#d9d9d9'
  const grey90 = '#e6e6e6'

  const defaultStyle: StylesConfig<SearchableSelectOption, false> = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      border: 'none',
      borderBottom: `1px solid ${error ? pink : midBlue}`,
      boxShadow: 'none',
      minHeight: 'auto',
      height: 'auto',
      padding: '1px 2px',
      '&:hover, &:focus-within': {
        border: 0,
        borderBottom: `1px solid ${error ? pink : midBlue}`
      }
    }),
    valueContainer: (provided) => ({ ...provided, padding: 0 }),
    input: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0
    }),
    menu: (provided) => ({
      ...provided,
      border: `1px solid ${grey85}`,
      boxShadow: 'none',
      borderRadius: 0,
      marginTop: 0
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : grey30,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? skyBlue : state.isFocused ? grey90 : 'white'
    }),
    singleValue: (provider, state) => ({
      ...provider,
      color: state.data.value === '' ? grey45 : midBlue,
      fontWeight: 300,
      opacity: '1'
    }),
    placeholder: (provided) => ({ ...provided, color: grey45, opacity: 1 }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: midBlue,
      cursor: 'pointer',
      padding: '0 8px',
      '&:hover': {
        color: skyBlue
      }
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: midBlue,
      cursor: 'pointer',
      padding: '0 8px',
      '&:hover': {
        color: skyBlue
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    })
  }

  const largeStyle: StylesConfig<SearchableSelectOption, false> = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      border: 'none',
      borderBottom: `1px solid transparent`,
      boxShadow: 'none',
      minHeight: 'auto',
      height: 'auto',
      padding: '1px 2px',
      '&:hover, &:focus-within': {
        border: 0,
        borderBottom: `1px solid ${midBlue}`
      }
    }),
    singleValue: (provider, state) => ({
      ...provider,
      color: state.data.value === '' ? grey45 : midBlue,
      fontWeight: 'normal',
      opacity: '1',
      '@media (min-width: 1312px)': {
        fontSize: '21px'
      }
    })
  }

  const customStyles = theme === 'large' ? { ...defaultStyle, ...largeStyle } : defaultStyle

  return (
    <ReactSelect
      styles={customStyles}
      value={selectedOption}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={(o: OnChangeValue<SearchableSelectOption, false>) => {
        if (updateSelectField) {
          updateSelectField(o as SearchableSelectOption)
          setSelectedOption(o as SearchableSelectOption)
        }
      }}
      classNamePrefix='react-select'
      options={options}
      {...props}
    />
  )
}
