import React from 'react'
import ReactDOM from 'react-dom'

import { SearchableSelect, SearchableSelectOption } from 'components/shared/SearchableSelect'
import { t } from 'i18n'

const customSelectTagWrappers: NodeListOf<Element> = document.querySelectorAll(
  '.js-custom-select-tag-wrapper'
)

customSelectTagWrappers.forEach((customSelectTagWrapper: Element) => {
  const selectField: HTMLSelectElement | null = customSelectTagWrapper.querySelector('select')

  if (selectField) {
    const customSelectTagContainer: HTMLElement | null =
      customSelectTagWrapper.querySelector('.js-custom-select-tag')

    if (customSelectTagContainer) {
      const theme: string = customSelectTagContainer.dataset.customSelectTheme || 'default'

      const ariaLabelId: string | undefined = customSelectTagContainer.dataset.ariaLabelId

      const selectFieldOptions: HTMLOptionElement[] = Array.from(selectField.options)
      const options: SearchableSelectOption[] = selectFieldOptions.map(
        (option: HTMLOptionElement) => ({
          value: option.value,
          label: option.label,
          selected: option.selected
        })
      )
      selectField.style.display = 'none'

      const selectedOption: SearchableSelectOption | undefined = options.find(
        (o: SearchableSelectOption) => o.selected
      )

      const updateSelectField = (o: SearchableSelectOption): void => {
        selectField.value = o.value as string
      }

      ReactDOM.render(
        <SearchableSelect
          options={options}
          initialValue={selectedOption}
          // eslint-disable-next-line react/jsx-no-bind
          updateSelectField={updateSelectField}
          placeholder={options[0].value === '' ? options[0].label : t('registration.not_selected')}
          aria-labelledby={ariaLabelId}
          theme={theme}
        />,
        customSelectTagContainer
      )
    }
  }
})
